import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/host.png"
import invite from "../images/blog/create.png"
import share from "../images/blog/share.png"

const InviteBlog = () => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Corona Guide")
    }
  }, [])

  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>
          <Yellow>Guide:</Yellow> Hold en fest selv Mette-mor kan li{"'"}
        </BlogHeader>
        <Teaser>
          Øv bøv. Det var ikke det 2020, som vi havde bedt om. En sommer uden
          kæmpe havefester, 18-års og konfirmationer er bare ikke fedt. Men nu
          er alt godt - eller hvad? <br />
          <br />
          Lige som vi troede, at den møg-virus var ovre, går vores kære Mette ud
          og siger, at vi ikke må holde fester. Det ser sort ud, men vi må få
          det bedste ud af det. Derfor har vi lavet en guide til “En fest selv
          Mette-mor kan li{"'"}".
        </Teaser>

        <Section>
          <HeaderContainer>
            <Header>Planlægning</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Vi starter med at planlægge festen. Her er der 2 ting, der skal
            ordnes: Indkøb og corona-sikker lokation
          </Paragraph>

          <Paragraph>
            <BoldSpan>Indkøb:</BoldSpan> Der er 3 essentielle ting, der skal
            købes, før du kan prale af at holde “En fest selv Mette-mor kan
            li'”:
            <br />
            <br />
            <UList>
              <ListItem>Masser af håndsprit</ListItem>
              <ListItem>Masser af plastikglas</ListItem>
              <ListItem>
                Og selvfølgelig alkohol (dog lidt mindre end normalt)
              </ListItem>
            </UList>
          </Paragraph>

          <Paragraph>
            <BoldSpan>Corona-sikker lokation:</BoldSpan> Før du inviterer alle
            dine venner og deres venner over til et brag af en fest, har vi et
            par ting, som Mette vil sætte pris på:
            <br />
            <br />
            <UList>
              <ListItem>
                Hver gæst skal have mindst 4 kvm. Spørg din far/mor, hvor stort
                huset er og inviter ud fra det. Men husk maks. 50.
              </ListItem>
            </UList>
            Hvis der ikke er plads til alle, så hellere split det op og hold en
            fest igen i næste weekend - så er der også noget at glæde sig til
            <span role="img" aria-label="wink">
              😉
            </span>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Invitation</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Det er ikke altid nemt at holde styr på, hvor mange der tropper op
            til festen. Vi kender allesammen til messengerafstemningen: “Kommer
            du?” - “Ja/Nej”, hvor halvdelen ikke svarer, og nogen tager en
            ekstra ven med. Selvom det er svært at undgå, gør Queue det i hvert
            fald mere overskueligt og nemt.
            <br />
            <br />
            <StyledLink to="/invite-guide">
              <Button>Send Invite med Queue</Button>
            </StyledLink>
            <br />
            For lige at kridte banen op, kan du sende et par regler med i, når
            du sender festinvitationen med Queue. Så undgår du er stå og skal
            råbe alle op, når musikken allerede spiller. Vi anbefaler dem her:
            <br />
            <br />
            <UList>
              <ListItem>Alle spritter af, når de ankommer</ListItem>
              <ListItem>Alle tager skoene af</ListItem>
              <ListItem>
                Alle skifter glas, når de har spillet beerpong
              </ListItem>
            </UList>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Festen</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Så begynder gæsterne så småt at ankomme, og der er lagt op til et
            brag af en fest. 4 ting som du skal tænke ekstra over:
            <br />
            <br />
            <UList>
              <ListItem>Lad ølbongen ligge i skuffen</ListItem>
              <ListItem>
                Ikke pat på samme glas. Skift glas på beerpong bordet
              </ListItem>
              <ListItem>
                Sprit af (i hvert fald, når gæsterne ankommer). Om det er med
                vodka eller håndsprit er ligemeget, bare det bliver gjort
                <span role="img" aria-label="wink">
                  😉
                </span>
              </ListItem>
              <ListItem>
                Og så den sværeste af dem allesammem: Prøv at hold lidt igen med
                alkoholen
                <span role="img" aria-label="wink">
                  🥴
                </span>
              </ListItem>
            </UList>
            Musikken skal selvfølgelig heller ikke glemmes. For at undgå at alle
            fedter rundt med den samme telefon, så kan Queue hjælpe igen. Med
            Queue kan alle sætte sange i kø fra deres egen telefon.
            <br />
            <br />
            <StyledLink to="/music-guide">
              <Button>Start musikken med Queue</Button>
            </StyledLink>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Dagen derpå</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Der er ikke noget værre end at vågne op til stanken af øl og vodka.
            Få overstået oprydningen med sprit og gulvmoppe. 3 ting du skal
            huske:
            <br />
            <br />
            <UList>
              <ListItem>
                Tag et par handsker på (især når du skal samle snus og
                cigaretter op i haven/på terrassen)
              </ListItem>
              <ListItem>
                Sprit alle vandrette flader af: Det er alt fra vindueskarme til
                bordflader.
              </ListItem>
              <ListItem>
                Få dåser og flasker ud så hurtigt som muligt, så ligger
                bakterierne ikke at hygge sig nede i resterne.
              </ListItem>
            </UList>
            <br />
            <br />
            Puha. Så burde det hele være det hele.
            <br />
            Tag{" "}
            <NormalLinkStyled
              href="https://www.instagram.com/queue_dk/"
              target="_blank"
            >
              @Queue_dk
            </NormalLinkStyled>{" "}
            til din coronavenlige fest.
            <br />
            <br />
            <Hash>#festformette</Hash> og god og sikker fest derude!
            <br />
            <br />
            <BoldSpan>/Queue-teamet</BoldSpan>
          </Paragraph>
        </Section>
        <BlogFooter />
      </Wrapper>
    </PageWrapper>
  )
}

export default InviteBlog

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1.2;

  margin-bottom: 3rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 400px;

  height: auto;

  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const BoldSpan = styled.span`
  font-weight: bold;

  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const UList = styled.ul`
  margin-left: 3em;

  @media (min-width: 500px) {
    margin-left: 3em;
  }

  @media (min-width: 650px) {
    margin-left: 3em;
  }

  @media (min-width: 750px) {
    margin-left: 3em;
  }
`
const ListItem = styled.li`
  margin-bottom: 5px;
`
// -------------

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 200px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 250px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 300px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`

const NormalLinkStyled = styled.a`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: #016fb9;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`

const Hash = styled(NormalLinkStyled)``
